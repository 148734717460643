import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/articles-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Add to your calendar to achieve your goals`}</h1>
    <p>{`I watched this video from Tim Ferris where he was answering a question that a viewer sent him. The question is how to manage different parts of life (creative, productive, spiritually) and how to balance them.`}</p>
    <div className="flex justify-center mt-12">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/PorwgeWg8Ew" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    </div>
    <blockquote>
      <p parentName="blockquote">{`If you want any semblance of covering these bases, my personal experience has to be calendared and scheduled in advance.`}</p>
    </blockquote>
    <p>{`This is very interesting and makes a lot of sense. One of my biggest issues is not having a path that I follow and I tend to work on whatever I want to, on that day.`}</p>
    <p>{`After watching this video, I realised that when I was using my calendar to try and divide the hours of my day to specific tasks, it made me work on those tasks and be more focus.`}</p>
    <h2>{`What I am doing`}</h2>
    <p>{`I have a goal to finish off all my projects that were just left hanging. I am forcing myself to not start a new thing before I have at last some sort of MVP version of these projects.`}</p>
    <p>{`In order to be more focused on what I want to do, I've started a bullet journal and also started using my calendar app to allocate parts of my day for a specific task.`}</p>
    <h2>{`Keep track of all the things`}</h2>
    <p>{`I usually keep my flight roster on my calendar, so I try to work around what sort of day and week that I will have.`}</p>
    <p>{`I also allocate 8 hours for sleep and one hour before sleep to do my evening ritual. `}</p>
    <p>{`Using the calendar allows me to see exactly how much time I can dedicate to a project, to code or just study something.  This also makes it easier to manage the chaos that shift work can cause at times.`}</p>
    <h2>{`Revising the calendar`}</h2>
    <p>{`Putting things in the calendar won't really do much if you don't look at it. So, I make sure that the first thing in the morning I look at the calendar, check how my day will look like.`}</p>
    <p>{`I also take at least 10 minutes every Sunday, looking at the next week. This will allow me to prepare for the new week and give that sense of urgency to achieve all the things scheduled.`}</p>
    <p>{`When a new month starts, I take up to 30 minutes to schedule my next month and allocate time slots to projects. I also set goals for that month so I have something that I try to achieve.`}</p>
    <h2>{`Evening Ritual`}</h2>
    <p>{`My evening ritual is pretty basic. I make sure I switch off all the electronic devices one hour before bed.`}</p>
    <p>{`In that hour, before bed, I take time to look over the bullet journal and see what I've worked on that day and what was left undone. With the rest of the hour, I tend to just read a book.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      